import styles from './characterAvatar.module.scss'
import unkownImage from '../../assets/images/unknown.jpg'
import { url } from '../../utils/Constants'
const CharacterAvatar = ({ characterId, title }) => {
  console.log('title', title);
  return (
    <div className={styles.avatarWrapper}>
      <img src={`${url}/static/characters/${characterId}/new-avatar.png`} alt="palphone_character_avatar" onError={(e) => (e.target.src = unkownImage)} />
      {title && <p>{title.length === 6 ? title : `+786-${title}`}</p>}
    </div>
  )
}
export default CharacterAvatar