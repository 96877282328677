import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage, isSupported } from 'firebase/messaging';
const config = {
  apiKey: "AIzaSyDhUNyKRhbf8L8nyWlJPnrMxE85xIsCxEo",
  authDomain: "palphone-d2284.firebaseapp.com",
  projectId: "palphone-d2284",
  storageBucket: "palphone-d2284.appspot.com",
  messagingSenderId: "641428859903",
  appId: "1:641428859903:web:d2fe3e98e1591dda3dd5ca",
  measurementId: "G-716PYYH25R"
}
const firebaseApp = initializeApp(config);
// const messaging = getMessaging(firebaseApp);
// export default messaging

// export const getFirebaseToken = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === 'granted') {
//       console.log('5555555555555555555555555');
//       getToken(messaging, { vapidKey: 'BMQK6jcMPdLne1zCd4lorgFN83T7iUMbBnFwIa8xPru89KKG1GVDWl_TXd20GGJh1LGH9hkSZI2ulVlpJLH_DM4' }).then((currentToken) => {
//         if (currentToken) {
//           console.log('current token', currentToken);
//           Cookies.set(cookiesLabel.FIREBASETOKEN, currentToken)
//           return currentToken

//         } else {
//           console.log('can not get token');
//         }
//       });


//     } else {
//       console.error('Permission denied');
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };
// export const getFirebaseToken = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === 'granted' && isSupported()) {
//       const messaging = getMessaging(firebaseApp);
//       return new Promise((resolve, reject) => {
//         getToken(messaging, { vapidKey: 'BMQK6jcMPdLne1zCd4lorgFN83T7iUMbBnFwIa8xPru89KKG1GVDWl_TXd20GGJh1LGH9hkSZI2ulVlpJLH_DM4' })
//           .then((currentToken) => {
//             if (currentToken) {
//               // console.log('current token', currentToken);
//               resolve(currentToken);
//             } else {
//               console.log('can not get token');
//               reject(new Error('Unable to get Firebase token'));
//             }
//           })
//           .catch((error) => {
//             console.error('Error getting Firebase token:', error);
//             reject(error);
//           });
//       });
//     } else {
//       console.error('Permission denied');
//       throw new Error('Permission denied for notifications');
//     }
//   } catch (error) {
//     console.error(error);
//     throw new Error("your browser doesn't support ");
//   }
// };

// export const getFirebaseToken = async () => {
//   try {
//     if ('Notification' in window && Notification.permission !== 'denied') {
//       const permission = await Notification.requestPermission();
//       if (permission === 'granted' && isSupported()) {
//         const messaging = getMessaging(firebaseApp);
//         return new Promise((resolve, reject) => {
//           getToken(messaging, { vapidKey: 'BMQK6jcMPdLne1zCd4lorgFN83T7iUMbBnFwIa8xPru89KKG1GVDWl_TXd20GGJh1LGH9hkSZI2ulVlpJLH_DM4' })
//             .then((currentToken) => {
//               if (currentToken) {
//                 resolve(currentToken);
//               } else {
//                 console.log('Unable to get token');
//                 reject(new Error('Unable to get Firebase token'));
//               }
//             })
//             .catch((error) => {
//               console.error('Error getting Firebase token:', error);
//               reject(error);
//             });
//         });
//       } else {
//         throw new Error('Permission denied for notifications');
//       }
//     } else {
//       console.error('Notification not supported or permission denied');
//       throw new Error("Notifications not supported or permission denied");
//     }
//   } catch (error) {
//     console.error(error);
//     throw new Error("Your browser doesn't support notifications");
//   }
// };
export const getFirebaseToken = async () => {
  try {
    // Rule 1: Checking isSupport and throw error
    if (!isSupported()) {
      throw new Error("Notifications not supported");
    }

    // Rule 2: Checking permission with error handling
    if ('Notification' in window) {

      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {

        throw new Error('Permission denied for notifications');
      }

      const messaging = getMessaging(firebaseApp);
      // Wait for the service worker to be ready
      const registration = await navigator.serviceWorker.ready;
      // Get Firebase token with error handling

      return new Promise((resolve, reject) => {
        getToken(messaging, {
          vapidKey: 'BMQK6jcMPdLne1zCd4lorgFN83T7iUMbBnFwIa8xPru89KKG1GVDWl_TXd20GGJh1LGH9hkSZI2ulVlpJLH_DM4', serviceWorkerRegistration: registration,
        })
          .then((currentToken) => {
            if (currentToken) {
              resolve(currentToken);
            } else {
              console.log('Unable to get token');
              reject(new Error('Unable to get Firebase token'));
            }
          })
          .catch((error) => {
            console.error('Error getting Firebase token:', error);
            reject(error);
          });
      });
    } else {
      throw new Error("Notifications not supported");
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message);
  }
};


// // Add an event listener for handling messages when the app is in the foreground
// onMessage(messaging, (payload) => {
//   console.log('Message received while app is in the foreground:', payload);
//   // Customize the behavior when the app is in the foreground
// });