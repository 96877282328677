import { useEffect, useState } from 'react';
import Keypad from '../../components/keypad/Keypad'
import MainLayout from '../../components/layout/mainLayout/MainLayout'
import styles from './home.module.scss'
import Modal from '../../components/modal/Modal';
import { Process_Status } from '../../utils/Constants';

const Home = ({ callManager, setProcessChanger }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  useEffect(() => {
    callManager.endCall({ sendReqToExitRoom: false, isForce: true, closeTheWs: true, calledFrom: "home page" })
    setProcessChanger(Process_Status.CREATINGCALL)
    // Send a message as soon as the component mounts
    // const message = 'Hello from this tab!';
    // const broadcastChannel = new BroadcastChannel('uniqueChannel');
    // broadcastChannel.postMessage(message);
    // console.log(message);

    // Function to handle messages received from other tabs
    // const handleTabMessage = (event) => {
    //   console.log('Received message from another tab:', event.data);
    //   // setIsModalOpen(true)
    // };

    // Set up event listener for messages
    // broadcastChannel.onmessage = handleTabMessage;

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      // broadcastChannel.close();
    };
  }, []);
  // const browserCloseHandler = () => {
  //   window.close();
  // }
  //close modal handler
  // const closeModal = () => {
  //   setIsModalOpen(false)
  // }
  return (
    <>
      {/* <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <div className={styles.modalContentCard}>
          <button onClick={browserCloseHandler}>Close</button>
        </div>
      </Modal> */}
      <div className={styles.homeContainer}>
        <MainLayout>
          <Keypad />
        </MainLayout>
      </div>
    </>
  )
}
export default Home