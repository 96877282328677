import { useEffect, useState } from 'react'
import styles from './keypad.module.scss'
import KeypadBtn from '../buttons/keyPadBtn/KeyPadBtn';
import { AiOutlineClose } from 'react-icons/ai'
import Modal from '../modal/Modal';
import DialBtn from '../buttons/dial/DialBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import DownloadBtn from '../buttons/downloadbtn/DownloadBtn';
import Cookies from 'js-cookie';
import { DeviceOS, cookiesLabel, path } from '../../utils/Constants';
import { toast } from 'react-toastify';
const Keypad = () => {
  const [palcode, setPalcode] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [devideOs, setDeviceOs] = useState('')
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const palcodeValue = queryParams.get('palcode');
    const palnumberValue = queryParams.get('palnumber');

    if (palcodeValue) {
      setPalcode(palcodeValue)
    }
    if (palnumberValue) {
      setPalcode(palnumberValue)
    }
  }, [location.search])
  useEffect(() => {
    const deviceOs = Cookies.get(cookiesLabel.DEVICEOS)
    setDeviceOs(deviceOs)

  }, [])


  //set palcode by keyboard numbers
  useEffect(() => {
    const handleKeyPress = (e) => {
      // Check if the pressed key is a number (0-9)
      if (/^[0-9]$/.test(e.key)) {
        // Check the length of the current state and limit it to 6 characters
        if (palcode.length < 10) {
          setPalcode((prevNumbers) => prevNumbers + e.key);
        }
      } else if (e.key === 'Backspace') {
        handleClearClick();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [palcode])


  //keypad Handle Click Fun
  const handleNumberClick = (number) => {
    if (palcode.length < 10) {
      setPalcode(palcode + number);
    }
  };
  //clear the last number
  const handleClearClick = () => {
    setPalcode(palcode.slice(0, -1));
  };

  //close modal handler
  const closeModal = () => {
    setIsModalOpen(false)
  }

  //dial button handler
  const onClickCallHadler = async () => {
    //check microphone permision

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (stream) {
        navigate(path.CALLING, { state: palcode })
      }
    } catch (error) {
      toast.error('Please grant microphone permission to proceed.')
    }
  }
  return (<>
    <Modal isOpen={isModalOpen} closeModal={closeModal}>
      <div className={styles.modalContentCard}>
        <div className={styles.closeBtn}>
          <AiOutlineClose size={25} onClick={closeModal} />
        </div>
        <div className={styles.modalText}>
          <strong>Web Call</strong>
          <p>
            Palphone web-call features are limited. Please install the mobile application to access all features.
          </p>
        </div>
        <div className={styles.actionBtn}>
          <button onClick={onClickCallHadler}>Call</button>
          {(devideOs === DeviceOS.ANDROID || devideOs === DeviceOS.IOS) && <DownloadBtn element='modal' onclickHandler={closeModal} device={devideOs} />}
        </div>
      </div>
    </Modal>
    <div className={styles.keypadContainer}>
      <div className={styles.inputContainer}>
        {palcode?.length === 0 ? 'Enter  Palcode or palnumber ' : palcode}
        {/* {[...Array(6)].map((_, index) => (
          <span key={index} style={{ margin: '0 5px' }} className={palcode[index] !== undefined ? styles.fadeInAnimation : ''} >
            {palcode[index] !== undefined ? palcode[index] : '-'}
          </span>
        ))} */}
      </div>
      <div className={styles.keysContainer}>
        <div className={styles.keysNumbers}>
          {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map((number) => (
            // <button key={number} onClick={() => handleNumberClick(number)}>{number}</button>
            <KeypadBtn key={number} label={number} onclick={handleNumberClick} />
          ))}
        </div>
        <div className={styles.actionContianer}>
          <button ></button>
          {palcode && palcode.length > 3 ? <DialBtn palcode={palcode} setPalcode={setPalcode} setIsModalOpen={setIsModalOpen} /> : <DialBtn disable />
          }
          <button onClick={() => handleClearClick()}>x</button>
        </div>
      </div>
    </div>
  </>
  )
}
export default Keypad