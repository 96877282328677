import styles from './talking.module.scss'
import MainLayout from '../../components/layout/mainLayout/MainLayout'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CharacterAvatar from '../../components/characterimage/CharacterAvatar';
import CallStatus from '../../components/callStatus/CallStatus';
import { DeviceOS, Process_Status, cookiesLabel, path } from '../../utils/Constants';
import { useEffect, useState } from 'react';
import { ImPhoneHangUp } from 'react-icons/im'
import { BsMicMute } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";
import { SlMicrophone } from "react-icons/sl";
import { MdCall } from 'react-icons/md'
import { IoClose } from "react-icons/io5";
import { toast } from 'react-toastify';
import Modal from '../../components/modal/Modal';
import { AiOutlineClose } from 'react-icons/ai'
import Cookies from 'js-cookie';
import DownloadBtn from '../../components/buttons/downloadbtn/DownloadBtn';
import { getMediaIp } from '../../services/requests';

const MAX_RETRIES = 2;
const Talking = ({ callManager, processStatus, setProcessChanger }) => {
  const location = useLocation()
  const { partner_character_id, talk_id, accessToken, accountId } = useSelector(state => state.auth);
  const { state } = location;
  const palcode = state ? state.palcode : null;
  const [isMute, setIsMute] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const [devideOs, setDeviceOs] = useState('')
  const [timeoutId, setTimeoutId] = useState(null);
  // console.log('ppppppppppppppppppppppppppppppppppppp', processStatus);
  // useEffect(() => {
  //   if (isFirstRender) {
  //     getMediaIp('/getMediaInfo').then(res => {
  //       console.log(res.data.ip);
  //       callManager.setMediaUrl(res.data.ip)
  //       callManager.setToken(accessToken)
  //       callManager.setCallId(String(talk_id))
  //       callManager.setName('webCall')
  //       callManager.setPublicKey('publicKey')
  //       callManager.CreateMediaWsConnecton(talk_id, accessToken, accountId, res.data.ip)
  //       callManager.initialize(false)
  //     }).catch(err => {
  //       toast.error('server is not reachableeeeeeeee')
  //       navigate(path.Home)
  //     })

  //     // callManager.CreateMediaWsConnecton(talk_id, accessToken, accountId, mediaUrl)
  //     // callManager.initialize(false)


  //     // callManager.setMediaUrl({ id: 1, url: "192.168.10.163:3021" })
  //     // console.log('666')
  //     // callManager.setGuestMediaUrl({ id: 1, url: "192.168.10.163:3021" })
  //     // console.log('777')
  //     // callManager.setMyPeerId(accountId)
  //     // console.log('888')
  //     // console.log('999')
  //     // callManager.setToken(accessToken)
  //     // callManager.setMakeAwareDispatcher((a) => {})
  //     // callManager.setName('webCall')
  //     // callManager.setAccountId(accountId)
  //     // const mediaIp = '192.168.10.163:3021'
  //     // callManager.setMyMediaPublicIp(mediaIp)
  //     // callManager.setCallId(String(talk_id))
  //     // callManager.setTalkHash(String(talk_id))
  //     // callManager.registerMediaWsMessageArrivalHandler((b) => { })
  //     // callManager.registerMediaWsStateToggler((b) => {})
  //     // callManager.initializeAWsConnectionWithMedia(true)
  //     // callManager.initialize({ callId: String(talk_id), talkHash: String(talk_id) })

  //     setIsFirstRender(false)
  //   }
  // }, [])
  useEffect(() => {
    let retryCount = 0;
    const fetchMediaIpWithRetries = () => {
      getMediaIp('/getMediaInfo').then(res => {
        callManager.setMediaUrl(res.data.ip)
        callManager.setToken(accessToken)
        callManager.setCallId(String(talk_id))
        callManager.setName('webCall')
        callManager.setPublicKey('publicKey')
        callManager.CreateMediaWsConnecton(talk_id, accessToken, accountId, res.data.ip)
        callManager.initialize(false);
      }).catch(err => {
        if (retryCount === MAX_RETRIES - 1) {
          // toast.error('Server is not reachable after multiple attempts.');
          setProcessChanger(Process_Status.APIERROR)
          // navigate(path.Home);
        } else {
          retryCount++;
          fetchMediaIpWithRetries();
        }
      });
    };

    if (isFirstRender) {
      fetchMediaIpWithRetries();
      setIsFirstRender(false);
    }
  }, [isFirstRender]);
  useEffect(() => {
    const deviceOs = Cookies.get(cookiesLabel.DEVICEOS)
    setDeviceOs(deviceOs)
  }, [])

  useEffect(() => {
    // Set a timeout to alert if processStatus remains 'creatingCall' for more than 30 seconds.
    const timeout = setTimeout(async () => {
      if (processStatus === Process_Status.CREATINGCALL) {
        // Trigger an alert or handle the situation as needed.
        // await callManager.endCall({ sendReqToExitRoom: true, isForce: true, closeTheWs: true, calledFrom: "talking page" })
        setProcessChanger(Process_Status.EXITROOM)
        // navigate(path.Home)
      }
    }, 30000);

    // Save the timeoutId for cleanup on component unmount or when processStatus changes.
    setTimeoutId((prevTimeoutId) => {
      if (prevTimeoutId) {
        clearTimeout(prevTimeoutId);
      }
      return timeout;
    });

    // Clean up the timeout on component unmount.
    return () => {
      clearTimeout(timeoutId);
    };
  }, [processStatus]);

  const muteMicrophone = () => {
    callManager.muteMicrophone()
    setIsMute(true)
  }
  const unMuteMicrophone = () => {
    callManager.unMuteMicrophone()
    setIsMute(false)
  }
  const backHandler = async () => {
    await callManager.endCall({ sendReqToExitRoom: false, isForce: true, closeTheWs: true, calledFrom: "talking page" })
    setProcessChanger(Process_Status.CREATINGCALL)
    navigate(path.Home)
  }
  const endCallHandler = async () => {
    navigate(path.Home)
    await callManager.endCall({ sendReqToExitRoom: true, isForce: true, closeTheWs: true, calledFrom: "talking page" })
    setProcessChanger(Process_Status.CREATINGCALL)

  }
  const redialHandler = async () => {
    await callManager.endCall({ sendReqToExitRoom: false, isForce: true, closeTheWs: true, calledFrom: "talking page" })
    setProcessChanger(Process_Status.CREATINGCALL)
    navigate(path.CALLING, { state: palcode })
  }
  //close modal handler
  const closeModal = () => {
    setIsModalOpen(false)
  }
  return (
    <MainLayout>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <div className={styles.modalContentCard}>
          <div className={styles.closeBtn}>
            <AiOutlineClose size={25} onClick={closeModal} />
          </div>
          <div className={styles.modalText}>
            {/* <strong>This Code is one-used</strong> */}
            <p>
              messaging is not enable in palphone web, download palphone app for messaging.
            </p>

          </div>
          <div className={styles.actionBtn}>

            {(devideOs === DeviceOS.ANDROID || devideOs === DeviceOS.IOS) && <DownloadBtn element='modal' onclickHandler={closeModal} device={devideOs} />}
            <button onClick={closeModal} >Close</button>
          </div>
        </div>
      </Modal>
      <div className={styles.callingContainer}>
        <CallStatus currentStatus={processStatus} palcode={palcode} />
        <CharacterAvatar characterId={partner_character_id} />
        {processStatus !== Process_Status.EXITROOM && processStatus !== Process_Status.APIERROR ? < div className={styles.actionsBtn}>
          {<div className={processStatus === Process_Status.CALLSTARTED ? styles.btnWrapper : styles.btnWDisabledrapper}>
            {processStatus === Process_Status.CALLSTARTED ? <button onClick={endCallHandler}><ImPhoneHangUp size={30} color='white' /></button> : <button ><ImPhoneHangUp size={30} color='white' /></button>}
            <p>End</p>
          </div>}
          {!isMute ? <div className={styles.btnWrapper}>
            <button onClick={muteMicrophone} ><BsMicMute size={30} /></button>
            <p>Mute</p>
          </div> : <div className={styles.btnWrapper}>
            <button onClick={unMuteMicrophone} style={{ backgroundColor: 'black' }}><SlMicrophone size={30} color='white' /></button>
            <p>UnMute</p>
          </div>}
          <div className={styles.btnWrapper}>
            <button onClick={() => setIsModalOpen(true)}><AiOutlineMessage size={30} /></button>
            <p>message</p>
          </div>
        </div> : processStatus === Process_Status.EXITROOM || processStatus === Process_Status.APIERROR ? <div className={styles.actionEndCallBtn}><button onClick={backHandler} ><IoClose size={40} color='white' /> </button><button onClick={redialHandler} ><MdCall size={40} color='white' /> </button></div> : null}
      </div>
    </MainLayout >
  )
}
export default Talking