// export const url = 'https://stg-coreapi.palphone.com'
export const url = 'https://coreapi.palphone.com'
export const socketurl = 'ws://ms.palphone.com/'
// export const socketurl = 'ws://192.168.10.163:3021'
export const httpMediaUrl = 'https//ms.palphone.com/'
// export const httpMediaUrl = '192.168.10.163:3021'



export const DeviceOS = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
  OTHER: 'OTHER'
}
export const path = {
  Home: '/',
  CALLING: '/calling',
  USERINFORM: '/inform',
  TALKING: '/talking',
  ENDCALL: '/endcall'
}

export const cookiesLabel = {
  ACCESSTOKEN: 'pal-auth',
  REFRESHTOKEN: 'pal-auth-refresh',
  ACCONTID: 'pal-accid',
  DEVICEOS: 'pal_os',
  DEVICEID: 'pal-did',
  SESSIONID: 'pal-sid',
  FIREBASETOKEN: 'pal-ft'
}

export const Encryption_Keys = {
  ACCESS_TOKEN_key: 'access-token-key',
  ACCOUNT_ID_KEY: 'account_id_key'
}

export const serverFeild = {
  appVersion: '1.0.0',
  Platform: 3,
  language_id: 1,
  charecter_id: 35,
  pablicKey: 'publicKey',
  peerName: 'webCall'
}
export const Call_Status = {
  CONNECTING: 'connecting',
  CALLING: 'calling',
  RINGING: 'ringing',
  PREPERING: 'prepering',
  TALKING: 'talking',
  REJECTED: 'rejected',
  NORESPONSE: 'noresponse'

}

export const mqtt_Message = {
  AVAILABLE: 'available',
  REJECT: "reject",
  BUSY: "busy",
  ANSWER: "answer"
}

export const cancelCallTypes = {
  MISSEDCALL: 'missedCall',
  RINGINGMISSEDCALL: 'ringMissedCallByWeb',
  CANCELCALL: 'cancelCall'
}

export const mediaApiParameters = {
  CALLID: 'callId',
  NAME: 'name',
  TYPE: 'type',
  TALKHASH: 'talkHash',
  PUBLICKEY: 'publicKey',
  MEDIAID: 'mediaId',
  CALLCREATEDAT: 'callCreatedAt',
  PEERCREATEDAT: 'peerCreatedAt',
  TRANSPORTID: 'transportId',
  DTLSPARAMETERS: 'dtlsParameters',
  PRODUCERTRANSPORTID: 'producerTransportId',
  RTPPARAMETERS: 'rtpParameters',
  KIND: 'kind',
  CONSUMERTRANSPORTID: 'consumerTransportId',
  RTPCAPABILITIES: 'rtpCapabilities',
  PRODUCERID: 'producerId',
  SCTPSTREAMPARAMETERS: 'sctpStreamParameters',
  LABEL: 'label',
  PROTOCOL: 'protocol',
  APPDATA: 'appData',
  ISLETSCALL: 'isLetsCall',
  MEDIAWASCRASHED: 'mediaWasCrashed',
  REQID: 'reqId',
  ISFORCE: 'isForce',
  PEERNAME: 'peerName',
  PEERID: 'peerId',
  TOOKEN: 'token'

}
export const TRANSPORT_TYPE = {
  send: 'SEND',
  recieve: 'RECEIVE',
  pipe: 'PIPE'
}
export const Process_Status = {
  CREATINGCALL: "creatingCall",
  CALLSTARTED: 'callStarted',
  InitilizeApp: 'initializeApp',
  EXITROOM: 'exitRoom',
  APIERROR: 'apiError',
  CALLSTARTED: 'callStarted'

}