export enum TRANSPORT_TYPE {
  send = 'SEND',
  recieve = 'RECEIVE',
  pipe = 'PIPE'
}
export type ConsumedPeersT = { [peerId: string]: { producerId: string, peerName: string, peerId: string } }
export type ConsumedDataPeersT = { [peerId: string]: { dataProducerId: string, peerName: string, peerId: string, streamId: number } }

export enum END_EVENT_TYPE {
  disconnect = "DISCONNECT",
  exitRoom = "EXIT_ROOM"
}

export type PeerLeftTheCallEventPayloadT = {
  peerId: string
  peerName: string
  type: END_EVENT_TYPE
}
export type DoDataConsumePayloadT = {
  dataProducerId: string,
  id: string,
  type: string,
  streamId?: number
  maxPacketLifeTime?: number
  ordered?: boolean
  maxRetransmits?: number
  protocol: string,
  appData: any
  label: string,
  otherPeerId: string,
  otherPeerName: string
}

export enum LogLevelT {
  alert = 1,  //Actions that must be taken care of immediately.
  critical = 2,   //Critical conditions.
  error = 3,  //Non-critical error conditions.
  warning = 4,  //Warning conditions that should be taken care of.
  notice = 5,   //Normal, but significant events.
  info = 6,  //Informational messages that require no action.
  debug = 7,  //debugging messages, if the developer enabled debugging at compile time.
  boundry = 8,

}
export enum LogScopeT {
  server,
  processListener,
  simultaneous,
  apiCall,
  controllerCall,
  mediasoup,
  listener,
  dataChannel,
  wsServer,
  httpsServer,
  serverInitilization,
  response,
  request,
  wsClient,
  controllers,
  mediaBroker,
  brokerEvent,
  socketAccess,
  messageBroker,
  peer,
  repo,
  middlewares,
  rest,
  webrtcTransport,
  directTransport,
  producer,
  dataProducer,
  consumer,
  dataConsumer,
  metric,
  remoteMedia,
  remotePeer,
  call,
  worker,
  workerPack,
  pendedHttpRequests,
  config,
  utilities,

}
export enum Process_Status {
  InitilizeApp = 'initializeApp',
  CREATINGCALL = "creatingCall",
  CALLSTARTED = 'callStarted',
  EXITROOM = 'exitRoom',
  APIERROR = 'apiError'
}