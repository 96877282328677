import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import store from './redux/slice';
import { BrowserRouter } from 'react-router-dom';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' })
    .then((registration) => {
      // Service worker registration successful
      // console.log('Firebase service worker registered: ', registration);
    })
    .catch((error) => {
      // Service worker registration failed
      console.error('Firebase service worker registration failed: ', error);
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <ToastContainer position="top-center" />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);


