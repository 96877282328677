
import styles from './downloadbtn.module.scss'
import { DeviceOS } from '../../../utils/Constants';
import { RiDownload2Fill } from 'react-icons/ri'
import { useEffect } from 'react';
const DownloadBtn = ({ device, element }) => {
  // const userAgent = navigator.userAgent;
  // alert(userAgent)
  // useEffect(() => {
  //   if (userAgent.includes("SAMSUNG")) {
  //     alert('Samsung')
  //   } else if (userAgent.includes("Huawei")) {
  //     alert('Huawei')
  //   } else if (userAgent.includes("iPhone")) {
  //     alert('iPhone')
  //   } else if (userAgent.includes("iPad")) {
  //     alert('iPad')
  //   } else {
  //     alert('oter')
  //   }
  // }, [])
  // useEffect(() => {
  //   const userAgent = window.navigator.userAgent;
  //   alert(userAgent)
  //   if (/SAMSUNG/i.test(userAgent)) {
  //     alert('SAMSUNG')
  //   } else if (/Huawei|Honor/i.test(userAgent)) {
  //     alert('Huawei')
  //   } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
  //     alert('iPad')
  //   }
  //   else {
  //     alert('other')
  //   }
  // }, []);

  const onClickHandler = (os) => {
    if (os === DeviceOS.IOS) {
      window.location.href = 'https://apps.apple.com/us/app/palphone/id1637595045';
    } else if (os === DeviceOS.ANDROID) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.palphone.pro.app';
    }
  }
  return (
    <>
      <div className={styles.DownloadBtnContainer}>
        {element === 'header' && <button onClick={() => onClickHandler(device)}>
          <RiDownload2Fill size={25} color='white' />
        </button>}
      </div>
      {element === 'modal' && <button className={styles.modalBtn} onClick={() => onClickHandler(device)}>
        download <RiDownload2Fill size={25} color='white' />
      </button>}
    </>

  )
}
export default DownloadBtn