import axios from 'axios';
import Cookies from 'js-cookie';
import { getFromCookies } from '../utils/helperFunctions';
import { Encryption_Keys, cookiesLabel } from '../utils/Constants';

export const http = axios.create({
  // baseURL: 'http://54.66.15.28:9902', // stage ip
  baseURL: 'https://stg-coreapi.palphone.com', // stage url
  // baseURL: 'https://coreapi.palphone.com', //core url


  // timeout: 10000, // Adjust the timeout as needed
  headers: {
    'Content-Type': 'application/json',
    // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODI0NDc3LCJwbGF0Zm9ybSI6MywiYXBwX3ZlcnNpb24iOiIxIiwiZGV2aWNlX2lkIjoiZmZmZmYiLCJleHAiOjE2OTgyMjMwMDR9.Uy-vaI2sein4hmUW3e4juDoKFNJBN42sC6-eDs9zt0Y`
    // You can set common headers here
  },
});
export const http2 = axios.create({
  // baseURL: 'http://54.66.15.28:9902', // stage ip
  // baseURL: 'https://stg-coreapi.palphone.com',  // stage url
  baseURL: 'https://coreapi.palphone.com', //core url

  // timeout: 10000, // Adjust the timeout as needed
  headers: {
    'Content-Type': 'application/json',
  },
});

export const media = axios.create({
  baseURL: 'https://ms.palphone.com/',  // stage url
  // baseURL: 'http://192.168.10.163:3023',  // stage url
  // timeout: 10000, // Adjust the timeout as needed
  headers: {
    'Content-Type': 'application/json',
  },
});

// Create an Axios interceptor to set the Authorization header
http.interceptors.request.use(async (config) => {
  const accessToken = getFromCookies(cookiesLabel.ACCESSTOKEN, Encryption_Keys.ACCESS_TOKEN_key)
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

// Create an Axios interceptor to set the Authorization header
http2.interceptors.request.use(async (config) => {
  const refreshToken = Cookies.get('pal-auth-refresh') // Assuming you have access to the Redux store
  if (refreshToken) {
    config.headers.Authorization = `Bearer ${refreshToken}`;
  }
  return config;
});


// Create an Axios interceptor to set the Authorization header
media.interceptors.request.use(async (config) => {
  const accessToken = getFromCookies(cookiesLabel.ACCESSTOKEN, Encryption_Keys.ACCESS_TOKEN_key)
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});